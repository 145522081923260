import { useEffect, useMemo } from 'react';
import { END } from 'redux-saga';
import { useRouter } from 'next/dist/client/router';

import { wrapper } from 'store/store';
import { LoadingComponent, MetaTags } from 'components';
import { routesPath } from 'utils/constants/routePath';
import { getAuthsData, clearAuthData } from 'utils/auths';

const Index = () => {
  const router = useRouter();

  const userData = useMemo(() => {
    return getAuthsData();
  }, []);

  useEffect(() => {
    if (
      !userData.token ||
      userData.token === 'undefined' ||
      userData.userId === process.env._ANONYMOUS_ID
    ) {
      // clearAuthData();
      router.push(routesPath.authentication);
    } else {
      router.push(routesPath.feedPath);
    }
  }, [userData]);

  return (
    <div>
      <MetaTags />

      {/* <LoadingComponent fullWidth color="#C2C2C2" variant="spin" /> */}
    </div>
  );
};

export const getStaticProps = wrapper.getStaticProps(async ({ store }: any) => {
  store.dispatch(END);
  await store.sagaTask.toPromise();
});

export default Index;
